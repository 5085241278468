import type { DeepReadonly } from '@modules/utils/utilityTypes';

export type AppConfig = DeepReadonly<{
  ORG_ID: string;
  API_ROOT: string;
  STAGE_HOST_LIVE: string;
  STAGE_HOST_PREVIEW: string;
  PREVIEW_F11_HOST: string;
  PREVIEW_NEUTRAL_HOST: string;
  YOUTUBE_API_KEY: string;
  DOWNLOAD_URL: string;
  EXPORT_URL: string;
  IMG_URL: string;
  FILE_URL: string;
  KEYCLOAK_REALM: string;
  KEYCLOAK_URL: string;
  KEYCLOAK_CLIENT_ID: string;
  APOLLO_CLIENT_DEV_TOOLS: boolean;
  THEME_URL: string;
  TRANSLATION_URL: string;
  // https://factor-eleven.atlassian.net/browse/FOUN-948: deprecate this variable
  THEME_DEFAULT?: string;
  DD_APP_ID: string;
  DD_CLIENT_TKN: string;
  DD_ENV: 'local' | string;
  FEATURES_BUCKET_URL: string;
  MAPBOX_URL: string;
  MAPBOX_TOKEN: string;
  MF_REMOTE_ADCREATOR_URL: string;
  MF_REMOTE_REPORTING_URL: string;
  INTERCOM_APP_ID: string; // use empty string to disable
  INTERCOM_MESSENGER_ENABLED: boolean;
  CREATIVE_CDN: string;
  CREATIVE_VERSIONS: string;
  TEMP_ADCREATOR_LIVE_RENDERING: boolean;
  TEMP_ADCREATOR_PRODUCT_FEED: boolean;
}>;

let appConfig: DeepReadonly<AppConfig>;

const appConfigRequiredKeys: { [P in keyof AppConfig]: true } = {
  ORG_ID: true,
  API_ROOT: true,
  STAGE_HOST_LIVE: true,
  STAGE_HOST_PREVIEW: true,
  PREVIEW_F11_HOST: true,
  PREVIEW_NEUTRAL_HOST: true,
  YOUTUBE_API_KEY: true,
  DOWNLOAD_URL: true,
  EXPORT_URL: true,
  IMG_URL: true,
  FILE_URL: true,
  KEYCLOAK_REALM: true,
  KEYCLOAK_URL: true,
  KEYCLOAK_CLIENT_ID: true,
  APOLLO_CLIENT_DEV_TOOLS: true,
  THEME_URL: true,
  TRANSLATION_URL: true,
  DD_APP_ID: true,
  DD_CLIENT_TKN: true,
  DD_ENV: true,
  FEATURES_BUCKET_URL: true,
  MAPBOX_URL: true,
  MAPBOX_TOKEN: true,
  MF_REMOTE_ADCREATOR_URL: true,
  MF_REMOTE_REPORTING_URL: true,
  INTERCOM_APP_ID: true,
  INTERCOM_MESSENGER_ENABLED: true,
  CREATIVE_CDN: true,
  CREATIVE_VERSIONS: true,
  TEMP_ADCREATOR_LIVE_RENDERING: true,
  TEMP_ADCREATOR_PRODUCT_FEED: true,
};

// https://factor-eleven.atlassian.net/browse/AR-2464
// TODO: Refactor this module and remove legacy pattern of fetching
// the app configuration, the configuration will always come from
// the window object.
// We can try to remove the loadAppConfig function and load and assert the
// app config the first time that getAppConfig is called.
export function assertInjectedAppConfig(): void {
  // if check for backwards compatibility
  if (window.appConfig) {
    const injectedAppConfig = window.appConfig;
    if (!injectedAppConfig || typeof injectedAppConfig !== 'object') {
      throw new Error(
        `Injected app configuration has the wrong format, app config value: ${injectedAppConfig}`,
      );
    }
    const missingAppConfigVariables: string[] = [];
    Object.keys(appConfigRequiredKeys).forEach((key) => {
      if (!(key in injectedAppConfig)) {
        missingAppConfigVariables.push(key);
      }
    });
    if (missingAppConfigVariables.length > 0) {
      throw new Error(
        `Injected app configuration is missing env variables, missing variables in app config: ${missingAppConfigVariables.join(
          ', ',
        )}`,
      );
    }
  }
}

export function loadAppConfigOverwrites(): Partial<AppConfig> {
  const appConfigOverwrite = localStorage.getItem('DEBUG_APP_CONFIG_OVERWRITE');

  if (appConfigOverwrite) {
    try {
      const partialAppConfig = JSON.parse(appConfigOverwrite);
      if (partialAppConfig && typeof partialAppConfig === 'object') {
        console.warn('DEBUG_APP_CONFIG_OVERWRITE applied', partialAppConfig);
        return partialAppConfig;
      }
    } catch (_e) {
      console.info('DEBUG_APP_CONFIG_OVERWRITE ignored, it has an invalid shape', {
        appConfigOverwrite,
      });
    }
  }

  return {};
}

export function loadAppConfig() {
  // if check for backwards compatibility
  if (window.appConfig) {
    appConfig = Object.freeze({ ...(window.appConfig as AppConfig), ...loadAppConfigOverwrites() });
  } else {
    const envConfig: AppConfig = {
      ORG_ID: import.meta.env.VITE_ORG_ID,
      API_ROOT: import.meta.env.VITE_API_ROOT,
      STAGE_HOST_LIVE: import.meta.env.VITE_STAGE_HOST_LIVE,
      STAGE_HOST_PREVIEW: import.meta.env.VITE_STAGE_HOST_PREVIEW,
      PREVIEW_F11_HOST: import.meta.env.VITE_PREVIEW_F11_HOST,
      PREVIEW_NEUTRAL_HOST: import.meta.env.VITE_PREVIEW_NEUTRAL_HOST,
      YOUTUBE_API_KEY: import.meta.env.VITE_YOUTUBE_API_KEY,
      DOWNLOAD_URL: import.meta.env.VITE_DOWNLOAD_URL,
      EXPORT_URL: import.meta.env.VITE_EXPORT_URL,
      IMG_URL: import.meta.env.VITE_IMG_URL,
      FILE_URL: import.meta.env.VITE_FILE_URL,
      KEYCLOAK_REALM: import.meta.env.VITE_KEYCLOAK_REALM,
      KEYCLOAK_URL: import.meta.env.VITE_KEYCLOAK_URL,
      KEYCLOAK_CLIENT_ID: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
      APOLLO_CLIENT_DEV_TOOLS: import.meta.env.VITE_APOLLO_CLIENT_DEV_TOOLS === 'true',
      THEME_URL: import.meta.env.VITE_THEME_URL,
      TRANSLATION_URL: import.meta.env.VITE_TRANSLATION_URL,
      DD_APP_ID: import.meta.env.VITE_DD_APP_ID,
      DD_CLIENT_TKN: import.meta.env.VITE_DD_CLIENT_TKN,
      DD_ENV: import.meta.env.VITE_DD_ENV,
      FEATURES_BUCKET_URL: import.meta.env.VITE_FEATURES_BUCKET_URL,
      MAPBOX_URL: import.meta.env.VITE_MAPBOX_URL,
      MAPBOX_TOKEN: import.meta.env.VITE_MAPBOX_TOKEN,
      MF_REMOTE_ADCREATOR_URL: import.meta.env.VITE_MF_REMOTE_ADCREATOR_URL,
      MF_REMOTE_REPORTING_URL: import.meta.env.VITE_MF_REMOTE_REPORTING_URL,
      INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
      INTERCOM_MESSENGER_ENABLED: import.meta.env.VITE_INTERCOM_MESSENGER_ENABLED === 'true',
      CREATIVE_CDN: import.meta.env.VITE_CREATIVE_CDN,
      CREATIVE_VERSIONS: import.meta.env.VITE_CREATIVE_VERSIONS,
      TEMP_ADCREATOR_LIVE_RENDERING: import.meta.env.VITE_TEMP_ADCREATOR_LIVE_RENDERING === 'true',
      TEMP_ADCREATOR_PRODUCT_FEED: import.meta.env.VITE_TEMP_ADCREATOR_PRODUCT_FEED === 'true',
    };

    appConfig = Object.freeze({
      ...envConfig,
      ...loadAppConfigOverwrites(),
    });
  }
}

export function getAppConfig() {
  if (!appConfig) {
    throw new Error('getAppConfig() called before loadAppConfig()');
  }
  return appConfig;
}
